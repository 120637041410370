<template>
  <div class="title py-4">
    <PartAttachmentsManager :part="part" @refresh="getSupplierPart"></PartAttachmentsManager>
  </div>
</template>


<script>

import PartAttachmentsManager from '../../../components/partAttachmentsManager/PartAttachmentsManager';


export default {
  name: 'PageBcmPartAttachments',
  components: {
    PartAttachmentsManager,
  },
  data() {
    return {
      part: null,
    };
  },
  created() {
    this.getSupplierPart();
  },
  methods: {
    /**
     * GET SUPPLIER PART
     */
    getSupplierPart() {
      this.$apiInstance
        .getSupplierPart(
          this.$route.params.supplierUUID,
          this.$route.params.partUUID
        )
        .then(
          (partData) => {
            this.part = partData;
          },
          /**
           * ERROR GET SUPPLIER PART
           */
          (error) => {
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
  },
};
</script>
