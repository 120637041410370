<template>
  <div>
    <v-skeleton-loader
      v-if="!partObject"
      type="card"
    ></v-skeleton-loader>
    <AttachmentsManager
      v-else
      :attachments="partObject?.attachments || []"
      :owner="$route.params.supplierUUID"
      :upload-status-and-progress="uploadStatusAndProgress"
      shared-with="warehouse"
      @addAttachment="addAttachment"
      @updateAttachment="updateAttachment"
      @downloadAttachment="downloadAttachment"
      @deleteAttachment="deleteAttachment"
      @refresh="refresh"
    ></AttachmentsManager>

    <UploadManager
      ref="UploadManagerRef"
      @success="uploadSuccess"
      @error="uploadError"
      @progress="uploadProgress"
      @xhr="uploadProgress"
    ></UploadManager>
  </div>
</template>

<script>
import { AttachmentsManager, UploadManager, ApiErrorParser, DownloadSupplierPartAttachmentFile } from '@cloudmanufacturingtechnologies/portal-components';

export default {
  name: 'PartAttachmentsManager',
  components: { AttachmentsManager, UploadManager },
  props: {
    /** The part object */
    part: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      partObject: null,
      uploadStatusAndProgress: null,
      attachmentsDownloadUrl: []
    };
  },
  watch: {
    part: {
      handler: function(newPart, oldPart) {
        this.init();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    init() {      
      if(this.part?._id) {
        this.partObject = Object.assign({}, this.part);
      }
    },
    refresh() {
      this.$emit('refresh');
    },
    addAttachment(attachment) {
      /**
       * Create Attachment
       */
      const attachmentBody = new this.$BcmModel.Attachment();
      attachmentBody.name = attachment.name;
      attachmentBody.description = attachment.description;
      attachmentBody.public = attachment.public;

      this.$apiInstance.addSupplierPartAttachment(this.$route.params.supplierUUID, this.$route.params.partUUID, attachmentBody)
        .then((part) => {
          const newAttachment = part.attachments.find(item => item.name === attachmentBody.name);
          if(newAttachment && newAttachment._id) {
            this.$refs.UploadManagerRef.uploadSupplierPartAttachment(
              attachment.file,
              this.$route.params.supplierUUID,
              this.$route.params.partUUID,
              newAttachment._id
            );
          }else{
            /**
           * {  
           *    xhr: xhr,
           *    success: false,
           *    error: true,
           *    errorMessage: null,
           *    progress: false,
           *    progressStatus: null,
           *    file: file,
           *    brandUUID,
           *    partUUID,
           *    attachmentUUID
           * }
           */
            this.uploadStatusAndProgress = {  
              xhr: null,
              success: false,
              error: true,
              errorMessage: 'Unable to add this attachment',
              progress: false,
              progressStatus: null,
              file: null,
              brandUUID: this.$route.params.supplierUUID,
              partUUID: this.$route.params.partUUID,
              attachmentUUID: null,
            };
          }
        }, (error) => {
          /**
           * {  
           *    xhr: xhr,
           *    success: false,
           *    error: true,
           *    errorMessage: null,
           *    progress: false,
           *    progressStatus: null,
           *    file: file,
           *    brandUUID,
           *    partUUID,
           *    attachmentUUID
           * }
           */
          this.uploadStatusAndProgress = {  
            xhr: null,
            success: false,
            error: true,
            errorMessage: ApiErrorParser.parse(error),
            progress: false,
            progressStatus: null,
            file: null,
            brandUUID: this.$route.params.supplierUUID,
            partUUID: this.$route.params.partUUID,
            attachmentUUID: null,
          };
        });
    },
    updateAttachment(attachment) {
      /**
       * Update Attachment
       */
      const attachmentBody = new this.$BcmModel.Attachment();
      attachmentBody.name = attachment.name;
      attachmentBody.description = attachment.description;
      attachmentBody.public = attachment.public;

      this.$apiInstance.modifySupplierPartAttachment(this.$route.params.supplierUUID, this.$route.params.partUUID, attachment._id, attachmentBody)
        .then((part) => {
          /**
           * {  
           *    xhr: xhr,
           *    success: false,
           *    error: true,
           *    errorMessage: null,
           *    progress: false,
           *    progressStatus: null,
           *    file: file,
           *    brandUUID,
           *    partUUID,
           *    attachmentUUID
           * }
           */
          this.uploadStatusAndProgress = {  
            xhr: null,
            success: true,
            error: false,
            errorMessage: null,
            progress: false,
            progressStatus: null,
            file: null,
            brandUUID: this.$route.params.supplierUUID,
            partUUID: this.$route.params.partUUID,
            attachmentUUID: attachment._id,
          };
        }, (error) => {
          /**
           * {  
           *    xhr: xhr,
           *    success: false,
           *    error: true,
           *    errorMessage: null,
           *    progress: false,
           *    progressStatus: null,
           *    file: file,
           *    brandUUID,
           *    partUUID,
           *    attachmentUUID
           * }
           */
          this.uploadStatusAndProgress = {  
            xhr: null,
            success: false,
            error: true,
            errorMessage: ApiErrorParser.parse(error),
            progress: false,
            progressStatus: null,
            file: null,
            brandUUID: this.$route.params.supplierUUID,
            partUUID: this.$route.params.partUUID,
            attachmentUUID: null,
          };
        });
    },
    downloadAttachment(attachment) {
      const [promise, req] = DownloadSupplierPartAttachmentFile.downloadSupplierPartAttachmentFile(
        this.$apiInstance,
        this.$route.params.supplierUUID,
        this.$route.params.partUUID,
        attachment._id,
        attachment.file.extension
      );
      this.$downloadProgress.addDownloadV2(req, attachment.name, attachment.file.extension, promise);
    },
    deleteAttachment(attachment) {
      this.$apiInstance.deleteSupplierPartAttachment(this.$route.params.supplierUUID, this.$route.params.partUUID, attachment._id)
        .then((data) => {
        }, (error) => {
          throw new Error('Error deleting attachment');
        });
    },
    uploadProgress(object) {
      /**
       * {  
       *    xhr: xhr,
       *    success: false,
       *    error: true,
       *    errorMessage: null,
       *    progress: false,
       *    progressStatus: null,
       *    file: file,
       *    brandUUID,
       *    partUUID,
       *    attachmentUUID
       * }
       */
      this.uploadStatusAndProgress = Object.assign({}, object);
    },
    uploadSuccess(object) {
      /**
       * {  
       *    xhr: xhr,
       *    success: false,
       *    error: true,
       *    errorMessage: null,
       *    progress: false,
       *    progressStatus: null,
       *    file: file,
       *    brandUUID,
       *    partUUID,
       *    attachmentUUID
       * }
       */
      this.uploadStatusAndProgress = Object.assign({}, object);;
    },
    uploadError(object) {
      /**
       * {  
       *    xhr: xhr,
       *    success: false,
       *    error: true,
       *    errorMessage: null,
       *    progress: false,
       *    progressStatus: null,
       *    file: file,
       *    brandUUID,
       *    partUUID,
       *    attachmentUUID
       * }
       */
      this.uploadStatusAndProgress = Object.assign({}, object);;
    }
  },
};
</script>
