var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title py-4"
  }, [_c('PartAttachmentsManager', {
    attrs: {
      "part": _vm.part
    },
    on: {
      "refresh": _vm.getSupplierPart
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }